<template>
  <div class="courses">
    <div class="d-flex justify-center">
      <div class="pa-4 d-flex flex-wrap full-width">
        <div class="full-width">
          <div class="text-h6 mb-5">
            {{ $vuetify.lang.locales[$vuetify.lang.current].contactUsVia }}
          </div>
          <a class="link-without-styles" target="_blank" href="https://wa.me/+543516328580/?text=Hola%21%20Quiero%20obtener%20informacion%20sobre%20los%20cursos%21">
            <v-btn
              block
              color="green darken-3"
              class="mb-5 white--text"
            >
              <v-icon
                left
                dark
              >
                mdi-whatsapp
              </v-icon>
              Whatsapp
            </v-btn>
          </a>
          <a class="link-without-styles" target="_blank" href="https://www.instagram.com/t.o.d3d/">
            <v-btn
              block
              class="mb-10 gradient-live white--text"
              @click="loader = 'loading3'"
            >
              <v-icon
                left
                dark
              >
                mdi-instagram
              </v-icon>
              Instagram
            </v-btn>
          </a>
        </div>
        <div v-if="(messageStatus === 'initial' || messageStatus === 'inProgress')" class="text-h6 mb-5">
          {{ $vuetify.lang.locales[$vuetify.lang.current].needMoreHelp }}
        </div>
        <v-card v-if="(messageStatus === 'initial' || messageStatus === 'inProgress')" width="100%" color="grey lighten-4 pa-5">
          <div class="text-h6">
            {{ $vuetify.lang.locales[$vuetify.lang.current].yourData }}
          </div>
          <br/>
          <v-text-field
            :label="$vuetify.lang.locales[$vuetify.lang.current].name"
            color="primary"
            v-model="name"
            :rules="[rules.required]"
            solo
            dense
          />
          <v-text-field
            :label="$vuetify.lang.locales[$vuetify.lang.current].surname"
            v-model="surname"
            :rules="[rules.required]"
            solo
            dense
          />
          <v-text-field
            :label="$vuetify.lang.locales[$vuetify.lang.current].phone"
            v-model="phone"
            :rules="[rules.required]"
            solo
            dense
          />
          <v-text-field
            :label="$vuetify.lang.locales[$vuetify.lang.current].email"
            v-model="email"
            :rules="[rules.required, rules.email]"
            solo
            dense
          />
          <v-textarea
            :label="$vuetify.lang.locales[$vuetify.lang.current].textAreaPlaceholder"
            solo
            v-model="message"
            name="input-7-4"
          ></v-textarea>
          <div>
            <v-btn @click="sendMessage" :disabled="isSending" color="secondary" class="float-right">
              {{ $vuetify.lang.locales[$vuetify.lang.current].send }}
            </v-btn>
          </div>
        </v-card>
        <div
          v-if="messageStatus === 'inProgress'"
        >
          <v-progress-circular
            indeterminate
            color="amber"
          ></v-progress-circular>
        </div>
        <div v-if="(messageStatus === 'success' || messageStatus === 'error')">
          <v-alert
            v-if="messageStatus === 'error'"
            dense
            outlined
            type="error"
          >
            Ocurrio un error al enviar tu consulta, intenta nuevamente.
          </v-alert>
          <v-alert
            v-if="messageStatus === 'success'"
            dense
            outlined
            type="success"
          >
            Gracias por tu consulta, te contactaremos a la brevedad.
          </v-alert>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from '@/firebaseSDK'

export default {
  name: 'Courses',

  data: () => ({
    name: '',
    surname: '',
    phone: '',
    email: '',
    message: '',
    rules: {
      required: value => !!value || 'Debes completar este campo.',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Email invalido.'
      },
    },
    messageStatus: 'initial'
  }),

  methods: {
    async sendMessage () {
      try {
        this.messageStatus = 'inProgress'
        db.collection("questions").add({
          name: this.name,
          surname: this.surname,
          phone: this.phone,
          email: this.email,
          message: this.message
        })
        // db.collection("mail").add({
        //   to: this.email,
        //   message: {
        //     subject: '',
        //     text:''
        //   },
        //   template: {
        //     name: 'contact',
        //     data: {
        //       name: this.name.charAt(0).toUpperCase() + this.name.slice(1),
        //       surname: this.surname.charAt(0).toUpperCase() + this.surname.slice(1)
        //     }
        //   }
        // })
        this.messageStatus = 'success'
      } catch (err) {
        this.messageStatus = 'error'
        console.log(err, 'err')
      }
    }
  },
  
  async mounted () {
  }
}
</script>

<style scoped>
.link-without-styles {
  text-decoration: none;
}

.gradient-live {
  background-image: linear-gradient(to right, #c231b4 , #f33056);
}

.full-width {
  width: 100%;
  max-width: 400px;
}
</style>
